import React from 'react';
import { Helmet } from 'react-helmet';

interface MetaHeadProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
}

const MetaHead: React.FC<MetaHeadProps> = ({
  title = 'Back the music that moves you | wearecoral',
  description = 'Back the music that moves you. Patronage that\'s transparent, collective, and easy.',
  image = 'https://www.wearecoral.org/wearecoral-og.png',
  url = 'https://www.wearecoral.org'
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
      
      {/* Keywords and author */}
      <meta name="keywords" content="wearecoral, Coral, Music Funding, Community Support, Music Scenes, Music Culture, Diverse Music, Grassroots Music, Artists Patronage Democratised" />
      <meta name="author" content="wearecoral Team" />

      {/* Open Graph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="wearecoral" />

      {/* Canonical URL */}
      <link rel="canonical" href={url} />
    </Helmet>
  );
};

export default MetaHead;