// eventDefinitions.ts
export var EventName;
(function (EventName) {
    EventName["HOME_PAGE_VIEW"] = "Home Page View";
    EventName["ABOUT_PAGE_VIEW"] = "About Page View";
    EventName["FOR_ARTIST_PAGE_VIEW"] = "For Artist Page View";
    EventName["TERMS_PAGE_VIEW"] = "Terms Page View";
    EventName["ARTIST_TERMS_PAGE_VIEW"] = "Artist Terms Page View";
    EventName["PRIVACY_PAGE_VIEW"] = "Privacy Page View";
    EventName["TRUST_PAGE_VIEW"] = "Trust Page View";
    EventName["CONTACT_PAGE_VIEW"] = "Contact Page View";
    EventName["PRINCIPLES_PAGE_VIEW"] = "Principles Page View";
    EventName["PUBLIC_SHARED_CORAL_VIEW"] = "Public Shared Coral View";
    EventName["PUBLIC_ARTIST_VIEW"] = "Public Artist View";
    EventName["VIEW_ALL_CONTRIBUTIONS_INTERACTION"] = "View All Contributions Interaction";
    EventName["FAQ_ITEM_VIEWED"] = "FAQ Item Viewed";
    EventName["COLLECTIVE_CALCULATOR_INTERACTION"] = "Collective Calculator Interaction";
    EventName["COLLECTIVE_TABLE_INTERACTION"] = "Collective Table Interaction";
    EventName["USER_ACCOUNT_CREATED"] = "User Account Created";
    EventName["USER_PROFILE_SETUP"] = "User Profile Setup";
    EventName["DASHBOARD_PAGE_VIEW"] = "Dashboard Page View";
    EventName["OUR_IMPACT_PAGE_VIEW"] = "Our Impact Page View";
    EventName["ARTIST_NOT_FOUND"] = "Artist Not Found";
    EventName["ARTIST_PROFILE_GENERATION"] = "Artist Profile Generation";
    EventName["ARTIST_PROFILE_URL_COPIED"] = "Artist Profile URL Copied";
    EventName["ARTIST_PROFILE_QR_CODE_DOWNLOADED"] = "Artist Profile QR Code Downloaded";
    EventName["ARTIST_BRAND_KIT_DOWNLOADED"] = "Artist Brand Kit Downloaded";
    EventName["BRANDED_IMAGE_VIEWED"] = "Branded Image Viewed";
    EventName["BRANDED_IMAGE_DOWNLOADED"] = "Branded Image Downloaded";
    EventName["ARTIST_CONNECTION_MADE"] = "Artist Connection Made";
    EventName["ARTIST_VERIFICATION_PAGE_VIEW"] = "Artist Verification Page View";
    EventName["ARTIST_VERIFICATION_SUBMITTED"] = "Artist Verification Submitted";
    EventName["IDENTIFICATION_PAGE_VIEW"] = "Identification Page View";
    EventName["IDENTIFICATION_SUBMITTED"] = "Identification Submitted";
    EventName["ARTIST_VERIFICATION_VIEW"] = "Artist Verification View";
    EventName["ARTIST_VERIFICATION_COMPLETE"] = "Artist Verification Complete";
    EventName["CREATE_CORAL_STARTED"] = "Create Coral Started";
    EventName["CORAL_CREATION_METHOD"] = "Coral Creation Method";
    EventName["CREATE_CORAL_SAVED"] = "Create Coral Saved";
    EventName["CREATE_CORAL_GOTO_PAYMENT"] = "Create Coral Goto Payment";
    EventName["CREATE_CORAL_COMPLETED"] = "Create Coral Completed";
    EventName["ADD_ARTIST_TO_CORAL"] = "Add Artist to Coral";
    EventName["REMOVE_ARTIST_FROM_CORAL"] = "Remove Artist from Coral";
    EventName["SIMULATE_CORAL"] = "Simulate Coral";
    EventName["BOOST_ARTIST_IN_CORAL"] = "Boost Artist in Coral";
    EventName["UPDATE_CORAL_NAME"] = "Update Coral Name";
    EventName["SHARE_CORAL"] = "Share Coral";
    EventName["CORAL_PLEDGE_AMOUNT_CHANGED"] = "Coral Pledge Amount Changed";
    EventName["CORAL_ARTIST_ADDED"] = "Coral Artist Added";
    EventName["CORAL_ARTIST_REMOVED"] = "Coral Artist Removed";
    EventName["CORAL_ARTIST_PINNED"] = "Coral Artist Pinned";
    EventName["CORAL_ARTIST_UNPINNED"] = "Coral Artist Unpinned";
    EventName["CORAL_FEES_EXPANDED"] = "Coral Fees Expanded";
    EventName["CORAL_FEES_COLLAPSED"] = "Coral Fees Collapsed";
    EventName["CORAL_GOTO_PAYMENT"] = "Coral Go to Payment";
    EventName["CORAL_CREATION_COMPLETED"] = "Coral Creation Completed";
    // Add more events as needed
})(EventName || (EventName = {}));
