import React, { useState, useEffect, useCallback, useRef } from 'react';
import CoralButton from '../../components/CoralButtons/CoralButton';
import SecondaryCoralButton from '../../components/CoralButtons/SecondaryCoralButton';
import coralIcon from '../../assets/images/coral-wordmark.png';
import { getCurrentUser } from '@aws-amplify/auth';
import { User } from 'shared/types/platformTypes';
import { getUserFromAPI } from '../../api/api';
import Spinner from '../../components/Spinner/Spinner';
import { useNavigate } from 'react-router-dom';

const Introduction: React.FC = () => {
    const [user, setUser] = useState<User | null>(null);
    const [page, setPage] = useState(0);
    const [animationStep, setAnimationStep] = useState(0);
    const [isPageComplete, setIsPageComplete] = useState(false);
    const navigate = useNavigate();
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const pages = [
        {
            content: [
                "Coral is built on the simple yet powerful idea of collective, coordinated action.",
                "Can we cooperate at scale to back the artists we love, nurturing music beyond the mainstream?"
            ],
            steps: 2,
            timing: [1000, 2000]
        },
        {
            content: [
                "It's simple: each of us creates a 'coral'—selecting artists we want to back with a monthly contribution.",
                "Every month, our coral shares that contribution among our chosen artists.",
                "These individual actions add up to meaningful funding that lets artists focus on what matters: music."
            ],
            steps: 3,
            timing: [1000, 2000, 3000]
        },
        {
            content: [
                "Like a thriving coral reef, our collective small contributions can create something beautiful.",
                "Together, let's write a new social contract for music - cultivating a vibrant and healthy ecosystem.",
                "final"
            ],
            steps: 3,
            timing: [1000, 2000, 3000]
        }
    ];

    const startPageTimer = useCallback(() => {
        clearTimeout(timerRef.current as NodeJS.Timeout);
        setAnimationStep(0);
        setIsPageComplete(false);

        const runTimer = (step: number) => {
            if (step < pages[page].steps) {
                timerRef.current = setTimeout(() => {
                    setAnimationStep(step);
                    runTimer(step + 1);
                }, pages[page].timing[step] - (step > 0 ? pages[page].timing[step - 1] : 0));
            } else {
                setIsPageComplete(true);
            }
        };

        runTimer(0);
    }, [page]);

    const goToNextStep = useCallback(() => {
        clearTimeout(timerRef.current as NodeJS.Timeout);
        if (animationStep < pages[page].steps - 1 || !isPageComplete) {
            setAnimationStep(pages[page].steps - 1);
            setIsPageComplete(true);
        } else if (page < pages.length - 1) {
            setPage(prev => prev + 1);
        }
    }, [animationStep, page, isPageComplete]);

    const skipToEnd = useCallback(() => {
        clearTimeout(timerRef.current as NodeJS.Timeout);
        setPage(pages.length - 1);
        setAnimationStep(pages[pages.length - 1].steps - 1);
        setIsPageComplete(true);
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const currentUser = await getCurrentUser();
                if (currentUser) {
                    const fetchedUserProfile = await getUserFromAPI(currentUser.userId) as User;
                    setUser(fetchedUserProfile);
                } else {
                    setUser({ firstName: '' } as User);
                }
            } catch (error) {
                setUser({ firstName: '' } as User);
            }
        };
        
        fetchUser();
    }, []);

    useEffect(() => {
        startPageTimer();
        return () => clearTimeout(timerRef.current as NodeJS.Timeout);
    }, [page, startPageTimer]);

    if (!user) {
        return (
            <div className="flex items-center justify-center h-screen">
                <Spinner />
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center justify-between min-h-screen max-h-[1000px] py-10 sm:py-16 sm:px-6 lg:px-8 font-nunito">
            <div className="max-w-xl w-full flex-grow">
                <h1 className="text-center text-3xl sm:text-4xl lg:text-5xl font-extrabold text-gray-900 mb-4">
                    {user.firstName ? `Welcome, ${user.firstName}.` : 'Welcome.'}
                </h1>

                <div className="min-h-[50vh] flex flex-col items-start justify-start">
                    {pages[page].content.map((text, index) => (
                        text !== "final" && animationStep >= index && (
                            <p key={index} className="text-center text-lg sm:text-xl lg:text-2xl mt-4 mb-4 px-4 sm:px-8 lg:px-16 font-nunito font-extrabold animate-fadeIn" dangerouslySetInnerHTML={{ __html: text }}></p>
                        )
                    ))}

                    {page === 2 && animationStep >= 2 && (
                        <div className="flex flex-col items-center justify-center w-full animate-fadeIn">
                            <img src={coralIcon} alt="coral" className="h-20 mt-24" />
                            <CoralButton 
                                onClick={() => navigate('/dashboard')} 
                                className="font-nunito font-extrabold text-lg sm:text-xl lg:text-2xl mt-24"
                            >
                                Let&apos;s make music together
                            </CoralButton>
                        </div>
                    )}
                </div>
            </div>

            {(page < 2 || (page === 2 && animationStep < 2)) && (
                <div className="flex justify-center mt-8 space-x-4 mb-14">
                    {isPageComplete ? (
                        <CoralButton onClick={goToNextStep} className="font-nunito font-bold text-sm animate-breathe">
                            Next
                        </CoralButton>
                    ) : (
                        <SecondaryCoralButton onClick={goToNextStep} className="font-nunito font-bold text-sm">
                            Next
                        </SecondaryCoralButton>
                    )}
                    <SecondaryCoralButton onClick={skipToEnd} className="font-nunito font-bold text-sm">Skip to End</SecondaryCoralButton>
                </div>
            )}
        </div>
    );
};

export default Introduction;