import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import coralReef from '../../assets/images/coral-reef.svg';
import blueWaveImage from '../../assets/images/blue-wave.png';
import darkBlueWaveImage from '../../assets/images/dark-blue-wave.png';
import { faqData } from '../Home/faqData';
import Spinner from '../../components/Spinner/Spinner';
import { storeReferralUrl } from '../../utils/referralUtils';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import { BlendedArtistListItem } from 'shared/types/platformTypes';
import { getArtistDetailsPublic } from '../../api/api';
import AllInOneCoralCreator from '../../components/AllInOneCoralCreator/AllInOneCoralCreator';
import FAQItem from '../../components/FAQItem/faqItem';
import CollectiveImpactCalculator from '../../components/CollectiveImpactTable/CollectiveImpactCalculator';
import CoralEcosystem from '../../components/CoralEcosystem/CoralEcosystem';
import { Tooltip } from 'react-tooltip';
import CoralButton from '../../components/CoralButtons/CoralButton';
import MetaHead from '../../components/MetaHead/MetaHead';

const PublicArtistLanding: React.FC = () => {
  const { id } = useParams();
  const location = useLocation();
  const [artistData, setArtistData] = useState<BlendedArtistListItem | null>(null);

  useEffect(() => {
    if (id) {
      const referralUrl = `${window.location.origin}${location.pathname}`;
      storeReferralUrl(referralUrl);
      fetchArtistData(id);
    }
  }, [id, location.pathname]);

  const fetchArtistData = async (artistUrl: string) => {
    try {
      const data = await getArtistDetailsPublic(artistUrl, undefined, undefined, true);
      setArtistData(data[0]);
      trackEvent(EventName.PUBLIC_ARTIST_VIEW, { artistUrl, artistName: data[0].artistName! });
    } catch (error) {
      console.error('Error fetching artist data:', error);
    }
  };

  const scrollToTop = useCallback(() => {
      console.log("Scroll function called");
      setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 100); // Delay to ensure DOM is fully rendered
  }, []);

  if (!artistData) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <MetaHead 
        title={`Back ${artistData?.artistName || 'Artist'} with Coral`}
        description={`Back ${artistData?.artistName || 'this artist'} with Coral`}
      />
      {/* Hero Section */}
      <section className="relative pt-8 sm:pt-16 md:pt-32 bg-no-repeat bg-contain bg-bottom 2xl:bg-cover" style={{ backgroundImage: `url(${coralReef})` }}>
        <div className="max-w-screen-xl mx-auto px-8 sm:mb-32 3xl:mb-96">
          <div className="flex flex-col md:flex-row md:items-start md:justify-between gap-8 pb-32 sm:pb-32 md:pb-48 lg:pb-96 xl:pb-96">
            <div className="md:w-1/2 2xl:mb-24">
              <h1 className="font-syne font-medium text-4xl sm:text-5xl md:text-6xl font-extrabold mb-4 leading-tight">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                  {artistData.artistName}
                </span>
              </h1>
              <p className="text-lg sm:text-xl md:text-2xl font-syne text-coral-black py-2 mb-6">
                {artistData.artistName}{artistData.artistName!.endsWith('s') ? '\'' : '\'s'} music moves you. Express that through coral, giving them more freedom to focus on what matters - music.
              </p>
              <p className="text-lg sm:text-xl font-nunito text-coral-black">
                Add your coral to the ecosystem to:
              </p>
              <ul className="text-lg sm:text-xl font-nunito text-coral-black space-y-2 py-2">
                <li>• Send micro-signals of appreciation for {artistData.artistName}{artistData.artistName!.endsWith('s') ? '\'' : '\'s'} music</li>
                <li>• Feel good moving from listener to regular contributor</li>
                <li>• Benefit from a living ecosystem for music</li>
              </ul>
              <div className="text-sm text-gray-400 mt-4">
                <span 
                  className="cursor-help" 
                  data-tooltip-id="compareTooltip" 
                  data-tooltip-html="wearecoral: 7.75% fee<br />Bandcamp: 10%-15% fee<br />Patreon: 8%-12% fee<br />Merchandise: 40-60% to artist<br />Streaming: <1% to artist per play"
                  >
                   + more of your monthly contribution reaches artists than on <span className="underline">other platforms</span>
                  </span>
                  <Tooltip id="compareTooltip" className="z-50" />
              </div>
            </div>
            <div className="md:w-1/2">
              <AllInOneCoralCreator artistId={artistData.artistId} />
            </div>
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="bg-coral-blue text-white">
        <div className="p-8 md:p-16">
          <div className="max-w-screen-xl mx-auto">
            <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between">
              <div className="lg:w-1/2 mb-8 lg:mb-0">
                <h2 className="text-xl md:text-2xl font-extrabold mb-2 sm:mb-4">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                    OUR VISION
                  </span>
                </h2>
                <h3 className="font-syne text-2xl md:text-4xl mb-0 sm:mb-8 leading-tight mr-0 sm:mr-12">
                  A thriving ecosystem where music is valued and diverse styles and scenes can prosper.
                </h3>
              </div>
              <div className="lg:w-1/2 mb-6 sm:mb-14">
                <CoralEcosystem />
              </div>
            </div>
          </div>
        </div>
        <img src={blueWaveImage} alt="Blue Wave" className="w-full" />
      </section>

      {/* How It Works Section */}
      <section className="bg-coral-deep-blue text-white">
        <div className="max-w-screen-xl mx-auto px-8 sm:px-6 lg:px-8">
          <h2 className="text-xl md:text-2xl font-extrabold mb-4">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
              CORAL DYNAMICS
            </span>
          </h2>
          <CollectiveImpactCalculator />
          <div className="flex justify-center mt-16">
            <CoralButton 
              onClick={scrollToTop} 
              className="text-lg px-8"
              popOnHover
            >
              Build Your Coral with {artistData.artistName}
            </CoralButton>
          </div>
        </div>
        <div className="relative mt-16">
          <img src={darkBlueWaveImage} alt="Dark Blue Wave" className="w-full" />
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-coral-dark-blue to-transparent"></div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="bg-coral-dark-blue text-white p-8 md:p-16">
        <div className="max-w-screen-xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            <div>
              <h2 className="text-xl md:text-2xl font-extrabold mb-4">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                  SUPPORT
                </span>
              </h2>
              <h3 className="font-syne text-3xl md:text-4xl mb-6 leading-tight">
                FAQs
              </h3>
              <p className="text-xl mb-8">
                Everything you need to know about backing {artistData.artistName} through wearecoral.
              </p>
            </div>
            <div className="space-y-4">
              {faqData.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>
          </div>
          <div className="flex justify-center mt-16">
              <CoralButton 
                onClick={scrollToTop}
                className="text-lg px-8"
                popOnHover
              >
                Start Your Coral with {artistData.artistName}
              </CoralButton>
            </div>
        </div>
      </section>
    </div>
  );
};

export default PublicArtistLanding;