// amplitudeTracker.ts
import * as amplitude from '@amplitude/analytics-browser';
// import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
export const initializeAmplitude = (apiKey) => {
    //  const sessionReplayTracking = sessionReplayPlugin({
    //     sampleRate: 1, // 100% sample rate, should reduce for production traffic.
    //   });
    //  amplitude.add(sessionReplayTracking);
    amplitude.init(apiKey, {
        defaultTracking: true,
    });
};
export const identifyUser = (userId) => {
    amplitude.setUserId(userId);
};
export const clearUserIdentity = () => {
    amplitude.reset();
};
export const trackEvent = (eventName, eventProperties) => {
    console.log(`Tracking event: ${eventName}`, eventProperties);
    amplitude.track(eventName, eventProperties);
};
