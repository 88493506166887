;
// Artist Pool Types
/**
* ArtistPoolType represents the type of an artist pool.
* It can be either 'fixed', 'dynamic', 'blended', or 'shared'.
*/
export var ArtistPoolType;
(function (ArtistPoolType) {
    ArtistPoolType["FIXED"] = "fixed";
    ArtistPoolType["DYNAMIC"] = "dynamic";
    ArtistPoolType["BLENDED"] = "blended";
    ArtistPoolType["SHARED"] = "shared";
})(ArtistPoolType || (ArtistPoolType = {}));
// Coral Types
/**
* CoralType represents the type of a coral.

* Corals can be of different types:
* - Shared: An existing base Coral that can added to a user's library
* - User: A Coral that is created manually by a user adding artists
*/
export var CoralType;
(function (CoralType) {
    CoralType["SHARED"] = "shared";
    CoralType["USER"] = "userManual";
    CoralType["PLATFORM"] = "platform";
})(CoralType || (CoralType = {}));
/**
* ArtistApplication represents an artist's application to the platform.
* It contains all the necessary information for the application process.
*/
// Define enums for company number types and public profile types
export var CompanyNumberType;
(function (CompanyNumberType) {
    CompanyNumberType["ABN"] = "ABN";
    CompanyNumberType["ACN"] = "ACN";
    CompanyNumberType["EIN"] = "EIN";
    CompanyNumberType["CRN"] = "CRN";
    CompanyNumberType["NZBN"] = "NZBN";
    CompanyNumberType["BN"] = "BN";
    CompanyNumberType["OTHER"] = "OTHER";
    // Add more types as needed
})(CompanyNumberType || (CompanyNumberType = {}));
export var PublicProfileType;
(function (PublicProfileType) {
    PublicProfileType["INSTAGRAM"] = "instagram";
    PublicProfileType["TIKTOK"] = "tiktok";
    PublicProfileType["FACEBOOK"] = "facebook";
    PublicProfileType["TWITTER"] = "twitter";
    PublicProfileType["YOUTUBE"] = "youtube";
    PublicProfileType["WEBSITE"] = "website";
    // Add more types as needed
})(PublicProfileType || (PublicProfileType = {}));
// Enums
/**
* Source represents the source of the artist pool.
* It can be 'Spotify' or any other source you want to add in the future.
*/
export var Source;
(function (Source) {
    Source["UNDEFINED"] = "Undefined";
    Source["USER"] = "User";
    Source["SHARED"] = "Shared";
    // Add other sources here
})(Source || (Source = {}));
/**
* SourceType represents the type of the source of the artist pool.
* It can be 'FavouriteArtists', 'ArtistsOnRotation', or 'ArtistsFollowed'.
*/
export var SourceType;
(function (SourceType) {
    SourceType["UNDEFINED"] = "Undefined";
    SourceType["USER_ADDED"] = "UserAdded";
    SourceType["USER_CONNECTED_CURATED_CORAL"] = "UserConnectedCuratedCoral";
    // Add other source types here}
})(SourceType || (SourceType = {}));
/**
* CoralSubscriptionStatus represents the status of a coral.
* It can be 'Pending', 'Active', 'Paused', or 'Archived'.
*/
export var CoralSubscriptionStatus;
(function (CoralSubscriptionStatus) {
    CoralSubscriptionStatus[CoralSubscriptionStatus["NONE"] = 1] = "NONE";
    CoralSubscriptionStatus[CoralSubscriptionStatus["PENDING"] = 2] = "PENDING";
    CoralSubscriptionStatus[CoralSubscriptionStatus["ACTIVE"] = 3] = "ACTIVE";
    CoralSubscriptionStatus[CoralSubscriptionStatus["PAUSED"] = 4] = "PAUSED";
})(CoralSubscriptionStatus || (CoralSubscriptionStatus = {}));
