import React, { useState, useCallback, useEffect } from 'react';
import ArtistSearch from '../ArtistSearch/ArtistSearch';
import { ArtistSearchResult, BlendedArtistListItem } from 'shared/types/platformTypes';
import { Copy, Check, AlertCircle, Info, Download } from 'lucide-react';
import useArtistFetchQueue from '../../hooks/useArtistFetch';
import Spinner from '../Spinner/Spinner';
import { Tooltip } from 'react-tooltip';
import ArtistBrandedImageCreator from '../ArtistBrandedImageCreator/ArtistBrandedImageCreator';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import { getArtistDetailsPublic, addArtistToMailingList, createCoralPublic } from '../../api/api';
import QRCodeComponent from '../QRCodeCreator/QRCodeCreator';

const ArtistProfileTools: React.FC = () => {
  const [selectedArtist, setSelectedArtist] = useState<BlendedArtistListItem | null>(null);
  const [email, setEmail] = useState('');
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [fetchedArtists, setFetchedArtists] = useState<BlendedArtistListItem[]>([]);
  const [lastSearchedArtist, setLastSearchedArtist] = useState<string | null>(null);

  const trackArtistProfileGeneration = useCallback(async (artistName: string, success: boolean) => {
    trackEvent(EventName.ARTIST_PROFILE_GENERATION, {
      artistName,
      email,
      success,
    });

    if (email) {
      try {
        await addArtistToMailingList(artistName, email);
      } catch (err) {
        console.error('Failed to add artist to mailing list:', err);
      }
    }

    if (error && lastSearchedArtist && email)
      setError(null);
  }, [email]);

  const createCoralWithSelectedArtist = useCallback(async (artistId: string) => {
    try {
      await createCoralPublic(artistId, true);
    } catch (error) {
      console.error("Error creating coral with artist:", error);
    }
  }, []);

  useEffect(() => {
    if (selectedArtist && selectedArtist.artistId) {
      createCoralWithSelectedArtist(selectedArtist.artistId);
    }
  }, [selectedArtist, createCoralWithSelectedArtist]);

  const handleError = useCallback((errorMessage: string) => {
    setError(errorMessage);
    if (lastSearchedArtist) {
      trackArtistProfileGeneration(lastSearchedArtist, false);
    }
  }, [lastSearchedArtist, trackArtistProfileGeneration]);

  const onArtistsFetched = useCallback((fetchedArtists: BlendedArtistListItem[]) => {
    setIsSearching(false);
    if (fetchedArtists[0].artistListItemStatus === 'not found') {
      handleError("Artist not found. Please add your email so we can contact you when we add this artist. We're continually expanding our database.");
      setFetchedArtists([]);
    }
    else if (fetchedArtists.length === 1) {
      setSelectedArtist(fetchedArtists[0]);
      setError(null);
      trackArtistProfileGeneration(fetchedArtists[0].artistName!, true);
    } else if (fetchedArtists.length > 1) {
      setFetchedArtists(fetchedArtists);
      setError(null);
    } else {
      setFetchedArtists([]);
      handleError("Artist not found. Please add your email so we can contact you when we add this artist. We're continually expanding our database.");
    }
  }, [trackArtistProfileGeneration, handleError]);

  const { addArtistToQueue } = useArtistFetchQueue(onArtistsFetched);

  const handleArtistSelect = useCallback(async (artist: ArtistSearchResult) => {
    setIsSearching(true);
    setError(null);
    setCopied(false);
    setSelectedArtist(null);
    setFetchedArtists([]);
    setLastSearchedArtist(artist.name);

    if (artist.guid) {
      try {
        const artistDetails = await getArtistDetailsPublic(undefined, undefined, artist.guid, true);
        if (artistDetails.length > 0) {
          setSelectedArtist(artistDetails[0]);
          trackArtistProfileGeneration(artistDetails[0].artistName!, true);
        } else {
          handleError("Unable to fetch artist details. Please try again.");
        }
      } catch (error) {
        console.error("Error fetching artist details:", error);
        handleError("An error occurred while fetching artist details. Please try again.");
      }
    } else {
      addArtistToQueue(artist.name);
    }
    setIsSearching(false);
  }, [addArtistToQueue, trackArtistProfileGeneration, handleError]);

  const handleSubmit = useCallback((artistName: string) => {
    setIsSearching(true);
    setError(null);
    setCopied(false);
    setSelectedArtist(null);
    setFetchedArtists([]);
    setLastSearchedArtist(artistName);
    addArtistToQueue(artistName);
  }, [addArtistToQueue]);

  useEffect(() => {
    if (lastSearchedArtist && error) {
      trackArtistProfileGeneration(lastSearchedArtist, false);
    }
  }, [email, lastSearchedArtist, error, trackArtistProfileGeneration]);

  const copyToClipboard = useCallback(() => {
    if (selectedArtist && selectedArtist.artistUrl) {
      navigator.clipboard.writeText(`${process.env.REACT_APP_HOSTNAME}/a/${selectedArtist.artistUrl}`)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
          trackEvent(EventName.ARTIST_PROFILE_URL_COPIED, {
            artistName: selectedArtist.artistName!,
            artistId: selectedArtist.artistId!,
          });
        })
        .catch(() => {
          setError("Failed to copy to clipboard. Please try again.");
        });
    }
  }, [selectedArtist]);

  const handleBrandKitDownload = useCallback(() => {
    if (selectedArtist) {
      trackEvent(EventName.ARTIST_BRAND_KIT_DOWNLOADED, {
        artistName: selectedArtist.artistName!,
        artistId: selectedArtist.artistId!,
      });
    }
  }, [selectedArtist]);

  return (
    <div className="max-w-4xl bg-white bg-opacity-10 backdrop-blur-md p-4 py-6 rounded-lg shadow-lg text-white">
      <div className="">
        <h2 className="text-xl md:text-2xl font-bold">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
            See Your Artist Page
          </span>
        </h2>
        <p className="text-base font-nunito mb-4 text-white/80">
          Instantly create your artist page. Allow your audience to back your art.
        </p>
        <div className="mb-4">
          <div className="relative flex items-center">
            <input
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Add email (optional)"
              className="w-full text-sm px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-coral-pink focus:border-transparent text-black"
              data-lpignore="true"
            />
            <div className="ml-2" data-tooltip-id="emailTooltip">
              <Info className="h-5 w-5 text-white" />
            </div>
          </div>
          <Tooltip id="emailTooltip" place="top" className='z-50'>
            We use this email to help you get the most from<br />
            wearecoral. For payment-related communications,<br />
            we&apos;ll use official channels (Instagram, Artist<br />
            Website) to reduce the risk of identity fraud.<br />
          </Tooltip>
        </div>
        <ArtistSearch
          onSelect={handleArtistSelect}
          onSubmit={handleSubmit}
          placeholder="Enter your artist name"
        />
      </div>

      {isSearching && (
        <div className="mt-4 p-4 bg-coral-dark-blue flex items-center justify-center animate-pulse">
          <Spinner className="mr-2 animate-spin text-coral-pink" />
          <span>Searching for artist... This may take up to 30 seconds as we connect to external services.</span>
        </div>
      )}

      {error && (
        <div className="mt-4 p-4 bg-coral-deep-blue border border-coral-pink text-coral-pink rounded-md flex items-center">
          <AlertCircle className="mr-2" />
          <span>{error}</span>
        </div>
      )}

      {fetchedArtists.length > 1 && (
        <div className="mt-4">
          <h3 className="text-white mb-2">Multiple artists found. Please select the correct one:</h3>
          <ul className="space-y-2">
            {fetchedArtists.map((artist) => (
              <li key={artist.artistId}>
                <button
                  onClick={() => setSelectedArtist(artist)}
                  className={`w-full p-2 rounded-md transition-colors duration-200 ${
                    selectedArtist === artist
                      ? 'bg-coral-pink text-white'
                      : 'bg-coral-deep-blue text-white hover:bg-coral-pink hover:text-white'
                  }`}
                >
                  {artist.artistName} {artist.disambiguation && `(${artist.disambiguation})`}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedArtist && selectedArtist.artistUrl && (
        <div className="mt-6">
          <h3 className="text-2xl font-bold mb-6 text-center text-white">
            {selectedArtist.artistName}
            {selectedArtist.disambiguation && (
              <span className="text-sm font-normal ml-2">({selectedArtist.disambiguation})</span>
            )}
          </h3>
          <div className="space-y-6">
            <div className="rounded-md transition-all duration-300 ease-in-out">
              <div className="flex items-center mb-4">
                <input
                  type="text"
                  value={`${process.env.REACT_APP_HOSTNAME!.replace(/^https:\/\/www\./, 'https://')}/a/${selectedArtist.artistUrl}`}
                  readOnly
                  className="flex-grow px-4 py-2 border border-coral-pink rounded-l-md bg-coral-darker-blue text-gray-700"
                />
                <button
                  onClick={copyToClipboard}
                  className="px-4 py-3 bg-gradient-to-r from-coral-pink to-coral-orange text-white rounded-r-md hover:from-coral-orange hover:to-coral-pink transition-all duration-300 ease-in-out h-full flex items-center justify-center"
                >
                  {copied ? <Check size={18} /> : <Copy size={18} />}
                </button>
              </div>
              {copied && (
                <p className="text-gray-200 mb-4 text-center animate-fade-in-down">URL copied to clipboard!</p>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-6">
                <div className="p-4 border border-dashed border-coral-pink rounded-md">
                  <QRCodeComponent 
                    url={`${process.env.REACT_APP_HOSTNAME}/a/${selectedArtist.artistUrl}?utm_source=qr_code&utm_medium=artist_profile&utm_campaign=${encodeURIComponent(selectedArtist.artistName!)}`} 
                  />
                </div>
                
                {/* Updated Brand Assets Download Box */}
                <div className="p-4 border border-dashed border-coral-pink rounded-md text-center">
                  <h4 className="text-lg font-semibold mb-2">Logo Kit</h4>
                  <p className="mb-4">Help your community discover and back you on wearecoral:</p>
                  <ul className="list-disc list-inside mb-4 text-sm">
                    <li>wearecoral logos for your Linktree and website</li>
                    <li>Instagram-ready post templates</li>
                    <li>Postcard and flyer design patterns</li>
                  </ul>
                  <a 
                    href={`https://wearecoral-public-data-prod.s3.ap-southeast-2.amazonaws.com/brand-assets/wearecoral-brand-assets.zip`}
                    download
                    className="inline-flex items-center px-4 py-2 bg-gradient-to-r from-coral-pink to-coral-orange text-white rounded-md hover:from-coral-orange hover:to-coral-pink transition-all duration-300 ease-in-out"
                    aria-label="Download wearecoral brand assets"
                    onClick={handleBrandKitDownload}
                  >
                    <Download size={18} className="mr-2" />
                    Download Logo Kit
                  </a>
                </div>
              </div>
              
              <div className="border border-dashed border-coral-pink rounded-md">
                <ArtistBrandedImageCreator 
                  qrCodeUrl={`${process.env.REACT_APP_HOSTNAME}/a/${selectedArtist.artistUrl}?utm_source=branded_image_creator&utm_medium=artist_profile&utm_campaign=${encodeURIComponent(selectedArtist.artistName!)}`} 
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArtistProfileTools;