import { CoralType } from "../types/platformTypes";
export class CoralSimulator {
    constructor(coral, transactionFee) {
        this.pledgeAmount = coral.pledgeAmount;
        this.strategyConfig = coral.coralRuleSet;
        this.coralGuid = coral.guid;
        this.pinnedArtists = coral.pinnedArtists;
        this.artistPool = this.prepareDataset(coral.artistPool.artistList);
        this.coralType = coral.type;
        this.actualTransactionFee = transactionFee;
        this.validateCoral();
    }
    validateCoral() {
        if (!this.strategyConfig.layers.some((layer) => layer.layerType === 'distribution')) {
            throw new Error("The 'distribution' layer is missing from the strategy configuration");
        }
        if (!this.artistPool.length) {
            throw new Error("There are no artists in the artist pool");
        }
        if (this.pinnedArtists.length > 2) {
            throw new Error("There are more than 2 artists in the pinned artists list");
        }
    }
    prepareDataset(artistList) {
        return artistList.map((artist) => {
            const artist_id = artist.artistId || "unknown";
            const artist_data = artist.artistData || {};
            const spotify_data = artist_data.spotifyData || {};
            return {
                artist_id: artist_id,
                artist_name: artist.artistName,
                source: artist.source,
                artist_status: artist.artistListItemStatus,
                genres: artist_id !== "unknown" ? artist_data.genres || [] : [],
                country: artist_id !== "unknown" ? artist_data.country || "" : "",
                spotify_popularity: artist_id !== "unknown" ? spotify_data.popularity || 0 : 0,
                exclude: artist_id === "unknown" || artist.artistListItemActive === false,
                reasons: artist_id === "unknown" ? ["Artist not found"] : [],
                included: false,
                allocation_percentage: undefined,
                allocated_amount: undefined
            };
        });
    }
    process() {
        let dataset = this.artistPool;
        if (this.coralType === CoralType.SHARED) {
            dataset = dataset.map(artist => ({
                ...artist,
                included: true,
                reasons: ['Included in shared coral']
            }));
        }
        else {
            const userFilteringLayer = new UserFilteringLayer(this.strategyConfig.layers.find((layer) => layer.layerType === 'userFiltering'), dataset);
            dataset = userFilteringLayer.apply();
        }
        const distributionLayer = new DistributionLayer(this.strategyConfig.layers.find((layer) => layer.layerType === 'distribution'), dataset);
        dataset = distributionLayer.apply();
        const pledgeAllocatorLayer = new PledgeAllocatorLayer(this.pledgeAmount, dataset, this.pinnedArtists, this.actualTransactionFee);
        const result = pledgeAllocatorLayer.apply();
        const outputArtistAllocations = result.artistAllocations.map(({ artist_id, artist_name, spotify_popularity, reasons, included, allocation_percentage, allocated_amount }) => ({
            artist_id,
            artist_name,
            spotify_popularity,
            included,
            reasons,
            allocation_percentage: allocation_percentage || 0,
            allocated_amount: Number(Number(allocated_amount || 0).toFixed(2)),
            impact_100_users: Math.round((allocated_amount || 0) * 100),
            impact_1000_users: Math.round((allocated_amount || 0) * 1000),
            impact_5000_users: Math.round((allocated_amount || 0) * 5000)
        }));
        outputArtistAllocations.sort((a, b) => {
            if (a.included === b.included) {
                return a.artist_id.localeCompare(b.artist_id);
            }
            return b.included ? 1 : -1;
        });
        if (this.actualTransactionFee && this.actualTransactionFee !== result.transactionFee) {
            throw new Error(`Transaction fee mismatch: Expected ${this.actualTransactionFee}, got ${result.transactionFee}`);
        }
        return {
            artistAllocations: outputArtistAllocations,
            transactionFee: result.transactionFee,
            platformFee: result.platformFee,
            coralGuid: this.coralGuid
        };
    }
}
export class UserFilteringLayer {
    constructor(filters, dataset) {
        this.filters = filters;
        this.dataset = dataset;
    }
    apply() {
        console.log(`Applying user filtering layer to dataset of record count ${this.dataset.length}...`);
        this.dataset = this.processDirectAddArtists(this.dataset);
        this.dataset = this.filterByGenre(this.dataset, this.filters);
        this.dataset = this.filterByLocation(this.dataset, this.filters);
        this.dataset = this.processDisabledArtists(this.dataset);
        console.log(`User filtering layer applied, dataset has record count ${this.dataset.length}`);
        return this.dataset;
    }
    processDirectAddArtists(dataset) {
        return dataset.map(artist => {
            if (artist.source === 'User' && artist.artist_status !== 'removed' && !artist.exclude) {
                artist.included = true;
                artist.reasons.push('Artist directly added by user');
            }
            return artist;
        });
    }
    processDisabledArtists(dataset) {
        return dataset.map(artist => {
            if (artist.artist_status === 'removed') {
                artist.exclude = true;
                artist.reasons.push('Artist disabled by user');
                artist.included = false;
            }
            return artist;
        });
    }
    filterByGenre(dataset, filters) {
        const genreFilters = filters.filters
            .filter((f) => f.filterType === 'genre')
            .map((f) => f.parameters.genre.toLowerCase());
        if (genreFilters.length) {
            return dataset.map(artist => {
                if (artist.source !== 'User' && !artist.genres.some(genre => genreFilters.includes(genre.toLowerCase()))) {
                    artist.exclude = true;
                    artist.reasons.push('Excluded by genre filter');
                }
                else if (artist.source !== 'User' && !artist.exclude) {
                    artist.included = true;
                    artist.reasons.push('Included by genre filter');
                }
                return artist;
            });
        }
        return dataset;
    }
    filterByLocation(dataset, filters) {
        const locationFilters = filters.filters
            .filter((f) => f.filterType === 'location')
            .map((f) => f.parameters.location);
        if (locationFilters.length) {
            return dataset.map(artist => {
                if (artist.source !== 'User' && !locationFilters.includes(artist.country)) {
                    artist.exclude = true;
                    artist.reasons.push('Excluded by location filter');
                }
                else if (artist.source !== 'User' && !artist.exclude) {
                    artist.included = true;
                    artist.reasons.push('Included by location filter');
                }
                return artist;
            });
        }
        return dataset;
    }
}
export class DistributionLayer {
    constructor(layerConfig, dataset) {
        this.layerConfig = layerConfig;
        this.dataset = dataset;
    }
    apply() {
        this.dataset = this.removeDuplicates();
        if (this.layerConfig.method === "EvenArtistSplit") {
            this.dataset = this.evenArtistSplit();
        }
        else {
            // Handle other distribution methods if needed
        }
        if (this.dataset.every(artist => artist.allocation_percentage === undefined)) {
            throw new Error("We noticed that all artists were removed after applying your rules. Please adjust your rules to include at least one artist in your Coral.");
        }
        return this.dataset;
    }
    removeDuplicates() {
        const uniqueArtists = new Map();
        this.dataset.forEach(artist => {
            if (!uniqueArtists.has(artist.artist_id) || artist.included) {
                uniqueArtists.set(artist.artist_id, artist);
            }
        });
        return Array.from(uniqueArtists.values());
    }
    evenArtistSplit() {
        const includedArtists = this.dataset.filter(artist => artist.included);
        const uniqueArtistsCount = new Set(includedArtists.map(artist => artist.artist_id)).size;
        if (uniqueArtistsCount === 0) {
            return this.dataset.map(artist => ({
                ...artist,
                allocation_percentage: 0
            }));
        }
        const basePercentage = 100 / uniqueArtistsCount;
        const roundedPercentage = Number(basePercentage.toFixed(6)); // Round to 6 decimal places
        let totalAllocated = 0;
        const allocatedArtists = this.dataset.map(artist => {
            if (artist.included) {
                totalAllocated += roundedPercentage;
                return {
                    ...artist,
                    allocation_percentage: roundedPercentage
                };
            }
            return {
                ...artist,
                allocation_percentage: 0
            };
        });
        // Adjust for any rounding errors
        if (totalAllocated !== 100 && uniqueArtistsCount > 0) {
            const difference = 100 - totalAllocated;
            allocatedArtists.find(artist => artist.included).allocation_percentage += difference;
        }
        return allocatedArtists;
    }
}
export class PledgeAllocatorLayer {
    constructor(pledgeAmount, dataset, pinnedArtists, actualTransactionFee) {
        this.pledgeAmount = pledgeAmount * 100; // Convert to cents
        this.dataset = dataset;
        this.pinnedArtists = pinnedArtists;
        this.actualTransactionFee = actualTransactionFee !== undefined ? actualTransactionFee * 100 : undefined;
    }
    calculatePaymentProcessingFee() {
        if (this.actualTransactionFee !== undefined) {
            return this.actualTransactionFee;
        }
        const transactionFee = PledgeAllocatorLayer.PAYMENT_PROCESSING_FEE_PERCENTAGE * this.pledgeAmount + PledgeAllocatorLayer.PAYMENT_PROCESSING_FEE_FIXED * 100;
        return Math.round(transactionFee);
    }
    calculatePlatformFee(remainingAmount) {
        const platformFee = PledgeAllocatorLayer.PLATFORM_FEE_PLUS_GST_PERCENTAGE * remainingAmount;
        return Math.round(platformFee);
    }
    calculatePinnedArtistsAmount(remainingAmount) {
        const pinnedArtistsAmount = PledgeAllocatorLayer.PINNED_ARTIST_PERCENTAGE * remainingAmount * this.pinnedArtists.length;
        return Math.round(pinnedArtistsAmount);
    }
    apply() {
        if (this.pledgeAmount === 0) {
            this.dataset = this.dataset.map(artist => ({
                ...artist,
                allocated_amount: 0,
                allocation_percentage: 0
            }));
            return {
                transactionFee: 0,
                platformFee: 0,
                artistAllocations: this.dataset
            };
        }
        const nanArtists = this.dataset.filter(artist => artist.allocation_percentage === undefined);
        this.dataset = this.dataset.filter(artist => artist.allocation_percentage !== undefined);
        const transactionFee = this.calculatePaymentProcessingFee();
        let remainingAmount = this.pledgeAmount - transactionFee;
        let platformFee = this.calculatePlatformFee(remainingAmount);
        const pinnedArtistsAmount = this.calculatePinnedArtistsAmount(remainingAmount - platformFee);
        let finalAmount = remainingAmount - platformFee - pinnedArtistsAmount;
        // Calculate allocations in cents first
        this.dataset = this.dataset.map(artist => ({
            ...artist,
            allocated_amount: Math.round((artist.allocation_percentage / 100) * finalAmount)
        }));
        const totalAllocatedAmount = this.dataset.reduce((sum, artist) => sum + artist.allocated_amount, 0);
        // Adjust platform fee to handle any rounding differences
        if (totalAllocatedAmount !== finalAmount) {
            platformFee += finalAmount - totalAllocatedAmount;
        }
        // Convert to dollars only at the end
        this.dataset = this.dataset.map(artist => ({
            ...artist,
            allocated_amount: Number((artist.allocated_amount / 100).toFixed(2)) // Fix to 2 decimal places
        }));
        if (this.pinnedArtists.length > 0) {
            const pinnedArtistAmount = Math.round(pinnedArtistsAmount / this.pinnedArtists.length);
            this.dataset = this.dataset.map(artist => {
                if (this.pinnedArtists.includes(artist.artist_id)) {
                    artist.allocated_amount = Number((artist.allocated_amount + pinnedArtistAmount / 100).toFixed(2));
                }
                return artist;
            });
        }
        this.dataset = [...this.dataset, ...nanArtists.map(artist => ({
                ...artist,
                allocation_percentage: 0,
                allocated_amount: 0
            }))];
        // Calculate final total using rounded values
        const totalDistributedAmount = Math.round(this.dataset.reduce((sum, artist) => sum + artist.allocated_amount, 0) * 100) + transactionFee + platformFee;
        // Use strict equality check after rounding
        if (totalDistributedAmount !== this.pledgeAmount) {
            platformFee += this.pledgeAmount - totalDistributedAmount;
        }
        return {
            transactionFee: Number((transactionFee / 100).toFixed(2)),
            platformFee: Number((platformFee / 100).toFixed(2)),
            artistAllocations: this.dataset
        };
    }
}
PledgeAllocatorLayer.PAYMENT_PROCESSING_FEE_PERCENTAGE = 0.029;
PledgeAllocatorLayer.PAYMENT_PROCESSING_FEE_FIXED = 0.30;
PledgeAllocatorLayer.PLATFORM_FEE_PLUS_GST_PERCENTAGE = 0.08525;
PledgeAllocatorLayer.PINNED_ARTIST_PERCENTAGE = 0.075;
