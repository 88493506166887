export const faqData = [
    {
        "question": "What makes wearecoral different from other platforms?",
        "answer": "Coral isn't another platform asking for your time and content. We're building a different story for music - one where communities can sustain their artists without the industry's transactional thinking. No content requirements, no merchandise, no tiered rewards. Just genuine connections between artists and the people who value their music."
    },
    {
        "question": "How does Coral work?",
        "answer": "Community members create 'corals' - their way of acknowledging what multiple artists mean to them through small, regular gestures. These micro-signals of appreciation come together to help sustain the music scenes they care about. It's about turning passive appreciation into active participation in your artistic journey."
    },
    {
        "question": "What's needed from artists?",
        "answer": "Nothing extra - keep creating your music. Your profile is generated instantly from just your artist name. If you want to share Coral with your community, you'll get a simple link for your socials, Linktree, or newsletter. Your community can even start backing you before you're involved. When they do, we'll reach out through your official channels."
    },
    {
        "question": "What's the bigger picture here?",
        "answer": `wearecoral is a social enterprise started by <a href="https://www.linkedin.com/in/dan-gooden-40191316/" style="color: #FC5373;" target='_blank' rel='noopener noreferrer'>Dan</a>, working to nurture a healthier ecosystem for music. We're moving away from the traditional industry model towards one where communities and scenes can sustain themselves. Our platform fees are lower than Bandcamp and Patreon, and 50% of profits go back into the music ecosystem. Connect via <a href="mailto:dan@wearecoral.org" style="color: #FC5373;">email</a> or <a href="https://www.linkedin.com/in/dan-gooden-40191316/" style="color: #FC5373;" target='_blank' rel='noopener noreferrer'>LinkedIn</a>.`
    },
    {
        "question": "How do contributions reach artists?",
        "answer": "When your community starts backing you, we'll send you a simple verification link and code. Add your PayPal email, and contributions will flow to you monthly. No complicated setup needed."
    },
    {
        "question": "Who is part of the ecosystem?",
        "answer": "Coral focuses on active artists who are creating and releasing music. We're building this for artists who can benefit most from community backing, so we don't include artists who have reached high commercial success or haven't released music in several years."
    },
    {
        "question": "How do you ensure meaningful impact?",
        "answer": "Each coral is limited in size to prevent dilution of impact. As our ecosystem grows, we continuously refine these parameters based on artist and community feedback to ensure meaningful contribution levels."
    },
    {
        "question": "Will I be able to connect with my community?",
        "answer": "Yes - we're developing ways for you to share updates with the community members who back you (if they opt in). The focus is on genuine connections, not content obligations."
    },
    {
        "question": "What if I'm not ready to participate?",
        "answer": "We understand it's a different approach. You have 9 months to decide if you want to receive your community's contributions. After that, they're returned to your supporters. You can always join later when it feels right - we're building this for the long term."
    }
]