import React, { useState, lazy, Suspense } from 'react';
import { AuthUser } from '@aws-amplify/auth';
import { useTheme, View, Image } from '@aws-amplify/ui-react';
import GenericCoralModal from '../../GenericCoralModal/GenericCoralModal';
import CoralButton from '../../CoralButtons/CoralButton';
import { requestMagicLink } from '../../../api/api';
import CoralLogo from '../../../assets/images/coral-icon.png';

const LazyAuthenticator = lazy(() => import('@aws-amplify/ui-react').then(module => ({
  default: module.Authenticator
})));

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAuthSuccess: (user: AuthUser) => void;
  initialSignIn?: boolean;
}

const AuthModal: React.FC<AuthModalProps> = ({ isOpen, onClose, onAuthSuccess, initialSignIn = false }) => {
  const [usePasswordLogin, setUsePasswordLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [message, setMessage] = useState('');
  const { tokens } = useTheme();

  const handleToggle = () => {
    setUsePasswordLogin(!usePasswordLogin);
    setMessage('');
    setIsEmailSent(false);
  };

  const handleEmailSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    try {
      await requestMagicLink(email);
      setIsEmailSent(true);
      setMessage('Sign-in link sent! Please check your email.');
    } catch (error) {
      setMessage('Error sending sign-in link. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const AuthComponents = {
    Header() {
      return null; // Remove the header from Authenticator
    },
  };

  const customAuthenticatorStyle = `
    [data-amplify-authenticator] {
      --amplify-components-authenticator-router-box-shadow: none;
      --amplify-components-authenticator-router-border-width: 0;
      --amplify-components-authenticator-form-padding: 0;
      --amplify-components-button-primary-background-color: var(--amplify-colors-pink-60);
      --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px var(--amplify-colors-pink-60);
      --amplify-components-tabs-item-active-border-color: var(--amplify-colors-pink-60);
      --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80);
      --amplify-components-tabs-item-active-color: var(--amplify-colors-pink-100);
      --amplify-components-button-link-color: var(--amplify-colors-pink-80);
    }
    [data-amplify-authenticator] [data-amplify-router] {
      background-color: transparent;
    }
  `;

  return (
    <GenericCoralModal isOpen={isOpen} onClose={onClose}>
      <style>{customAuthenticatorStyle}</style>
      <div className="flex flex-col items-center">
        <View textAlign="center" padding={tokens.space.medium}>
          <Image
            alt="Coral logo"
            src={CoralLogo}
            style={{ width: '60px', height: '60px' }}
          />
        </View>
        
        {!usePasswordLogin ? (
          <div className="w-full max-w-sm px-6">
            {!isEmailSent ? (
              <form onSubmit={handleEmailSubmit} className="py-8">
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-8"
                  placeholder="Enter your email"
                  required
                />
                <CoralButton
                  onClick={() => {}}
                  className="w-full"
                  disabled={isLoading}
                >
                  {isLoading ? 'Sending...' : 'Continue with Email'}
                </CoralButton>
              </form>
            ) : (
              <div className="py-8 text-center">
                <p className="text-lg font-semibold mb-2">Check your email</p>
                <p className="text-sm text-gray-600 mb-4">{message}</p>
                <p className="text-sm text-gray-600">
                  Didn&apos;t receive the email? 
                  <button 
                    onClick={() => setIsEmailSent(false)} 
                    className="ml-1 text-pink-600 hover:text-pink-800 underline"
                  >
                    Try again
                  </button>
                </p>
              </div>
            )}
          </div>
        ) : (
          <div className="w-full max-w-md">
            <Suspense fallback={<div>Loading...</div>}>
              <LazyAuthenticator
                initialState={initialSignIn ? 'signIn' : 'signUp'}
                components={AuthComponents}
                loginMechanisms={['email']}
              >
                {({ user }) => {
                  if (user) {
                    onAuthSuccess(user);
                  }
                  return (<></>); // Return empty element to satisfy typescript
                }}
              </LazyAuthenticator>
            </Suspense>
          </div>
        )}
        <button 
          onClick={handleToggle} 
          className="mt-4 text-sm text-gray-600 hover:text-gray-800 underline"
        >
          {usePasswordLogin ? 'Use passwordless login' : 'Use a password'}
        </button>
      </div>
    </GenericCoralModal>
  );
};

export default AuthModal;