// artistSelectionUtils.ts
import { Source } from "../types/platformTypes";
export function processSingleArtistSelection(artist, activeFilters) {
    var _a;
    if (((_a = artist.artistData) === null || _a === void 0 ? void 0 : _a.artistActive) === false) {
        return false;
    }
    if (artist.source !== Source.USER) {
        if (activeFilters && activeFilters.length > 0) {
            const genreFilters = activeFilters.filter(filter => filter.filterType === 'genre');
            const locationFilters = activeFilters.filter(filter => filter.filterType === 'location');
            const meetsGenreCriteria = genreFilters.length === 0 || genreFilters.some(filter => { var _a, _b; return (_b = (_a = artist.artistData) === null || _a === void 0 ? void 0 : _a.genres) === null || _b === void 0 ? void 0 : _b.includes(filter.value); });
            const meetsLocationCriteria = locationFilters.length === 0 || locationFilters.every(filter => { var _a; return ((_a = artist.artistData) === null || _a === void 0 ? void 0 : _a.country) === filter.value; });
            return meetsGenreCriteria && meetsLocationCriteria;
        }
        else {
            return false;
        }
    }
    return true;
}
export function extractActiveFilters(coral) {
    const filters = [];
    coral === null || coral === void 0 ? void 0 : coral.coralRuleSet.layers.forEach(layer => {
        if (layer.layerType === 'userFiltering' && layer.filters) {
            layer.filters.forEach(filter => {
                Object.entries(filter.parameters).forEach(([key, value]) => {
                    if (key === 'genre' || key === 'location') {
                        filters.push({ filterType: key, value });
                    }
                });
            });
        }
    });
    return filters;
}
