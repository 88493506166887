
import React from 'react';
import Spinner from '../Spinner/Spinner';

const PageLoader = () => (
  <div className="flex justify-center items-center min-h-[60vh]">
    <Spinner className="w-8 h-8" />
  </div>
);

export default PageLoader;