// src/index.tsx

import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import './amplifyConfig';
import { router } from './routes/routerConfig';
import { AuthProvider } from './components/Auth/AuthContext/AuthContext';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);
