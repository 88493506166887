export function normalizeName(name) {
    // Convert to lowercase
    let normalized = name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    // Replace common abbreviations and symbols
    const replacements = {
        '&': 'and',
        '+': 'and',
        '@': 'at',
        '№': 'number',
        '№.': 'number',
        'no.': 'number',
        'vol.': 'volume',
        'versus': 'vs',
        'feat.': 'featuring',
        'ft.': 'featuring',
    };
    for (const [key, value] of Object.entries(replacements)) {
        const escapedKey = key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        normalized = normalized.replace(new RegExp(`\\b${escapedKey}\\b`, 'g'), value);
    }
    // Handle apostrophes: remove them but join the words
    normalized = normalized.replace(/(\w+)'s/g, '$1s') // possessives
        .replace(/'/g, ''); // other apostrophes
    // Remove punctuation and symbols, except for hyphens between words
    // Use a Unicode-aware approach to preserve non-Latin characters
    normalized = normalized.replace(/[^\p{L}\p{N}\s-]|\p{Punctuation}/gu, '')
        .replace(/--+/g, '-') // Replace multiple hyphens with single hyphen
        .replace(/^-|-$/g, ''); // Remove leading/trailing hyphens
    // Replace multiple spaces with a single space and trim
    normalized = normalized.replace(/\s+/g, ' ').trim();
    // Remove common articles from the beginning (for Latin script names)
    normalized = normalized.replace(/^(the|a|an)\s+/i, '');
    // Remove Roman numerals from the end (up to XX)
    normalized = normalized.replace(/\s+(i|ii|iii|iv|v|vi|vii|viii|ix|x|xi|xii|xiii|xiv|xv|xvi|xvii|xviii|xix|xx)$/i, '');
    // Remove common suffixes (for Latin script names)
    normalized = normalized.replace(/\s+(band|group|orchestra|ensemble|quartet|trio|duo)$/i, '');
    return normalized;
}
