import React, { useState, useEffect, useRef, KeyboardEvent } from 'react';
import { Search } from 'lucide-react';
import Spinner from '../Spinner/Spinner'; // Adjust the import path as needed

interface ArtistPayoutCheckerProps {
  presetArtist?: string;
}

const ArtistPayoutChecker: React.FC<ArtistPayoutCheckerProps> = ({ presetArtist }) => {
  const [artists, setArtists] = useState<string[]>([]);
  const [artistPayouts, setArtistPayouts] = useState<Record<string, number>>({});
  const [inputValue, setInputValue] = useState<string>('');
  const [amount, setAmount] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const stage = process.env.REACT_APP_ENVIRONMENT || 'dev';
        const url = `https://wearecoral-public-data-${stage}.s3.ap-southeast-2.amazonaws.com/wearecoral-contribution-report.csv`;
        const response = await fetch(url);
        const text = await response.text();
        const rows = text.split('\n').slice(1); // Skip header
        
        const artistSet = new Set<string>();
        const payouts: Record<string, number> = {};

        rows.forEach(row => {
          const [, , amount, artistName] = row.split(',');
          if (artistName && artistName !== 'artistName' && !artistName.toLowerCase().includes('fee')) {
            artistSet.add(artistName);
            payouts[artistName.toLowerCase()] = (payouts[artistName.toLowerCase()] || 0) + parseFloat(amount);
          }
        });

        setArtists(Array.from(artistSet).sort());
        setArtistPayouts(payouts);
        setLoading(false);
      } catch (err) {
        setError('Failed to load artist data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const updateAmount = (artist: string) => {
    const payout = artistPayouts[artist.toLowerCase()] || 0;
    setAmount(payout.toFixed(2));
  };

  useEffect(() => {
    if (presetArtist) {
      setInputValue(presetArtist);
      updateAmount(presetArtist);
    }
  }, [presetArtist]);

  useEffect(() => {
    if (inputValue.length >= 3) {
      const exactMatch = artists.find(artist => artist.toLowerCase() === inputValue.toLowerCase());
      if (exactMatch) {
        updateAmount(exactMatch);
      } else {
        setAmount(null);
      }
    } else {
      setAmount(null);
    }
  }, [inputValue, artists, artistPayouts]);

  const filteredArtists = artists.filter(artist => 
    artist.toLowerCase().includes(inputValue.toLowerCase()) && inputValue.length >= 3
  );

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedIndex(prev => (prev < filteredArtists.length - 1 ? prev + 1 : prev));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedIndex(prev => (prev > 0 ? prev - 1 : 0));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (focusedIndex >= 0 && focusedIndex < filteredArtists.length) {
        setInputValue(filteredArtists[focusedIndex]);
        setShowDropdown(false);
        updateAmount(filteredArtists[focusedIndex]);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-16">
        <Spinner className="w-6 h-6 text-coral-pink" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-sm">
        {error}
      </div>
    );
  }

  // If we have a preset artist, return simplified view
  if (presetArtist) {
    return (
      <div className="w-full p-6 bg-white rounded-lg shadow-md">
        <h2 className="font-nunito text-xl font-bold text-coral-black mb-3">
          Your Available Funds
        </h2>
        <div className="flex items-center">
          <span className="text-gray-600 mr-2">Funds to Collect:</span>
          <span className="text-xl font-bold text-coral-pink">
            ${amount || '0.00'}
          </span>
        </div>
      </div>
    );
  }

  // Original return for search functionality
  return (
    <div className="w-full max-w-4xl p-3 sm:p-4 bg-white rounded-lg shadow-md text-gray-600">
      <h2 className="font-nunito text-lg sm:text-xl font-bold text-coral-black mb-2 sm:mb-3">
        Check Your Available Funds
      </h2>
      <p className="text-xs sm:text-sm text-gray-600 mb-3">
        Enter an artist name to see their current payout amount.
      </p>
      <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
        <div className="w-full sm:w-1/2 relative" ref={inputRef}>
          <input
            type="text"
            className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-coral-pink"
            placeholder="Enter artist name"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              setShowDropdown(true);
              setFocusedIndex(-1);
            }}
            onKeyDown={handleKeyDown}
            onFocus={() => setShowDropdown(true)}
          />
          <button
            onClick={() => updateAmount(inputValue)}
            disabled={!inputValue}
            className={`absolute right-2 top-1/2 transform -translate-y-1/2 p-1 rounded-full
              ${!inputValue
                ? 'text-gray-300 cursor-not-allowed'
                : 'text-coral-pink hover:bg-coral-pink hover:text-white'
              }`}
          >
            <Search size={16} />
          </button>
          {showDropdown && inputValue.length >= 3 && filteredArtists.length > 0 && (
            <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-40 overflow-auto">
              {filteredArtists.map((artist, index) => (
                <li
                  key={index}
                  className={`px-3 py-1 text-sm hover:bg-gray-100 cursor-pointer ${
                    index === focusedIndex ? 'bg-gray-100' : ''
                  }`}
                  onClick={() => {
                    setInputValue(artist);
                    setShowDropdown(false);
                    updateAmount(artist);
                  }}
                >
                  {artist}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="w-full sm:w-1/2 flex items-center">
          {amount !== null ? (
            <>
              <span className="text-sm text-gray-600 mr-2">Funds to Collect:</span>
              <span className="text-xl font-bold text-coral-pink">${amount}</span>
            </>
          ) : (
            <span className="text-sm text-gray-500 italic"></span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArtistPayoutChecker;